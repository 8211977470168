import AudioPlayer from 'react-h5-audio-player';
import './audio.css'

export const Audio = ({ src, ...props }) => {
    return (
        <AudioPlayer
            autoPlay={true}
            loop={true}
            src={src}
            onPlay={e => console.log("onPlay")}
        />
    )
}