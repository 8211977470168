import { colors } from "../assets/constants/colors";

export const socialIcons = [
  {
    name: "LinkedIn",
    path: "https://www.linkedin.com/in/zernach/",
    class: "maskedLinkedIn hover",
    tintColor: colors.blue,
  },
  {
    name: "GitHub",
    path: "https://github.com/zernach/",
    class: "maskedGithub hover",
    tintColor: colors.yellow,
  },
  {
    name: "Twitter",
    path: "https://twitter.com/zernach/",
    class: "maskedTwitter hover",
    tintColor: colors.green,
  },
  {
    name: "Spotify",
    path: "https://open.spotify.com/playlist/4skyRST9UzDsL8QRrbgC80?si=SerVNTzXR3u4sP1T8y6k_Q/",
    class: "maskedSpotify hover",
    tintColor: `${colors.green}60`,
  },
  {
    name: "YouTube",
    path: "https://www.youtube.com/channel/UCTcyHgRop949pvRCqdwnQXw/",
    class: "maskedYoutube hover",
    tintColor: colors.pink,
  },
  {
    name: "Instagram",
    path: "https://www.instagram.com/zernach/",
    class: "maskedInstagram hover",
    tintColor: `${colors.beach}99`,
  },
  {
    name: "Facebook",
    path: "https://www.facebook.com/zernach/",
    class: "maskedFacebook hover",
    tintColor: `${colors.white}99`,
  },
  {
    name: "Pinterest",
    path: "https://www.pinterest.com/zernach/",
    class: "maskedPinterest hover",
    tintColor: `${colors.white}60`,
  },
];
