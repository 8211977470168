

import "../../App.css"
import React, { useMemo } from "react"
import { Header } from "../header/header";
import { Footer } from "../footer/footer";

/**
 * Describe the new component here...
 */
export const Screen = (props) => {

    const styles = useMemo(() => {
        return {
            screen: {
                'textAlign': 'center',
                'backgroundSize': 'cover',
                'backgroundRepeat': 'no-repeat',
                'backgroundPosition': 'center',
                'display': 'flex',
                'flexDirection': 'column',
                'listStyle': 'none',
            }
        }
    }, [])

    return (
        <>
            <div className={props?.containerClass ?? "screen"} style={styles.screen}>
                <Header />
                <div className="screenBody hexagonsDivider" style={{ backgroundColor: '#00000099' }}>
                    {props?.children}
                </div>
                <div className="vshapedBottomDivider" style={{ height: '100px', backgroundColor: '#00000099' }}></div>
                <Footer />
            </div>
        </>
    );
};
