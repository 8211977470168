import React, { createContext, useCallback, useState, useEffect } from "react";

export const WebContext = createContext({
  onPressOpenLink: ({ link }) => {},
  onPressOpenLinkInNewTab: ({ link }) => {},
  windowHeight: 0,
  windowWidth: 0,
});

export const WebContextProvider = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Function to update window width and height when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // The empty dependency array ensures this effect runs once after initial render

  const onPressOpenLink = useCallback(({ link }) => {
    window.location.href = link;
  }, []);

  const onPressOpenLinkInNewTab = useCallback(({ link }) => {
    window.open(link, "_blank");
  }, []);

  return (
    <WebContext.Provider
      value={{
        onPressOpenLink,
        onPressOpenLinkInNewTab,
        windowWidth,
        windowHeight,
      }}
    >
      {children}
    </WebContext.Provider>
  );
};
