import { Container, Image, Row, Col, Button } from "react-bootstrap";
import { Accordion } from "../../components/accordion/accordion";
import { useBlogPostFunctions } from "./blog-post-functions";
import { Screen } from "../../components/screen/screen";
import { colors } from "../../assets/constants/colors";
import * as React from "react";
import "../../App.css";

/**
 * Blog Post Screen to be used as a re-usable template screen for all blog posts
 */
export const BlogPost = () => {
  const { onPressOpenLinkInNewTab, blogPost } = useBlogPostFunctions();

  return (
    <Screen>
      {blogPost ? (
        <div>
          <Row>
            <Col xs={12} sm={12} md={5} lg={6}>
              <div
                className={"d-flex justify-content-center align-items-center"}
              >
                <Col>
                  {blogPost?.image && (
                    <Image
                      src={blogPost?.image}
                      alt={blogPost?.title}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        backgroundColor:
                          blogPost?.imagesBackgroundColor || colors.archDark,
                        boxShadow: `0px 0px 10px 0px ${blogPost?.highlightColor}99`,
                        borderRadius: "40px",
                        maxHeight: 500,
                        maxWidth: 500,
                      }}
                    />
                  )}
                </Col>
              </div>
            </Col>
            <Col xs={12} sm={12} md={7} lg={6}>
              <h1 style={{ marginBottom: "5vh" }}>
                {`${blogPost?.title}`?.toUpperCase()}
              </h1>
              <h3 style={{ marginBottom: "5vh", color: "#ffffff99" }}>
                {`${blogPost?.subtitle}`}
              </h3>
              <div style={{ paddingLeft: "2.5vw", paddingRight: "2.5vw" }}>
                <>
                  {blogPost?.introduction?.summary && (
                    <p
                      style={{
                        color: blogPost?.highlightColor,
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {"SUMMARY"}
                      <span
                        style={{ color: colors.grey, fontWeight: "normal" }}
                      >
                        {` — ${blogPost?.introduction?.summary}`}
                      </span>
                    </p>
                  )}
                </>
                <>
                  {blogPost?.introduction?.techStack && (
                    <p
                      style={{
                        color: blogPost?.highlightColor,
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {"TECH STACK"}
                      <span
                        style={{ color: colors.grey, fontWeight: "normal" }}
                      >
                        {` — ${blogPost?.introduction?.techStack}`}
                      </span>
                    </p>
                  )}
                </>
                <>
                  {blogPost?.introduction?.teamAndTime && (
                    <p
                      style={{
                        color: blogPost?.highlightColor,
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {"TEAM & TIME"}
                      <span
                        style={{ color: colors.grey, fontWeight: "normal" }}
                      >
                        {` — ${blogPost?.introduction?.teamAndTime}`}
                      </span>
                    </p>
                  )}
                </>
                <>
                  {blogPost?.introduction?.time && (
                    <p
                      style={{
                        color: blogPost?.highlightColor,
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {"TIME"}
                      <span
                        style={{ color: colors.grey, fontWeight: "normal" }}
                      >
                        {` — ${blogPost?.introduction?.time}`}
                      </span>
                    </p>
                  )}
                </>
                <>
                  {blogPost?.introduction?.personalContributions && (
                    <>
                      <p
                        style={{
                          color: blogPost?.highlightColor,
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        {"PERSONAL CONTRIBUTIONS"}
                        <span
                          style={{ color: colors.grey, fontWeight: "normal" }}
                        >
                          {` — `}
                        </span>
                      </p>
                      {blogPost?.introduction?.personalContributions?.map(
                        (personalContribution, index) => (
                          <p
                            key={`index${index}`}
                            style={{
                              color: colors.grey,
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            {` ▻ ${personalContribution}`}
                          </p>
                        )
                      )}
                    </>
                  )}
                </>
                <Row style={{ marginTop: "50px" }}>
                  {blogPost?.links?.map(({ title, url, icon }, index) => (
                    <Col key={`index${index}`}>
                      <div
                        onClick={() => onPressOpenLinkInNewTab({ link: url })}
                      >
                        <Button
                          size="lg"
                          style={{
                            backgroundColor: `${blogPost?.highlightColor}99`,
                            borderWidth: 0,
                          }}
                        >
                          {icon}
                          <span style={{ marginLeft: "10px" }}>{title}</span>
                        </Button>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
          {blogPost?.video && (
            <Container>
              <iframe
                src={blogPost?.video}
                title="Video on Ryan Zernach Website"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{
                  height: "40vw",
                  width: "70vw",
                  borderRadius: "20px",
                  boxShadow: `0px 0px 10px 0px ${blogPost?.highlightColor}99`,
                  alignSelf: "center",
                  marginTop: "100px",
                  marginRight: "50px",
                }}
              />
            </Container>
          )}
          <Container style={{ marginTop: "80px" }}>
            {blogPost?.body?.map(
              (
                {
                  image,
                  url,
                  text,
                  caption,
                  video,
                  color,
                  spacer,
                  accordions,
                  imageGrid,
                },
                index
              ) => (
                <div
                  className={"d-flex justify-content-center align-items-center"}
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                  key={`index${index}`}
                >
                  <Col>
                    {image && (
                      <>
                        <Image
                          src={image}
                          // alt={title}
                          onClick={
                            url
                              ? () => onPressOpenLinkInNewTab({ link: url })
                              : null
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                            backgroundColor:
                              blogPost?.imagesBackgroundColor ||
                              colors.archDark,
                            boxShadow: `0px 0px 10px 0px ${blogPost?.highlightColor}99`,
                            borderRadius: "40px",
                            maxHeight: 800,
                            maxWidth: 800,
                            cursor: url ? "pointer" : null,
                          }}
                        />
                        {caption && (
                          <h6
                            style={{
                              marginTop: "5px",
                              marginBottom: "15px",
                              textAlign: "center",
                              color: "#ffffff99",
                              marginLeft: "10vw",
                              marginRight: "10vw",
                            }}
                          >
                            {caption}
                          </h6>
                        )}
                      </>
                    )}
                    {video && (
                      <iframe
                        src={video}
                        title="Video on Ryan Zernach Website"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{
                          height: "40vw",
                          width: "70vw",
                          borderRadius: "20px",
                          boxShadow: `0px 0px 10px 0px ${blogPost?.highlightColor}99`,
                          alignSelf: "center",
                        }}
                      />
                    )}
                    {text && (
                      <h3
                        style={{
                          color,
                          marginTop: "15px",
                          marginBottom: "15px",
                          textAlign: "left",
                        }}
                      >
                        {text}
                      </h3>
                    )}
                    {imageGrid && (
                      <Container style={{ marginTop: "5vh" }}>
                        {imageGrid?.title && (
                          <h4
                            style={{
                              marginTop: "5px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            {imageGrid?.title}
                          </h4>
                        )}
                        <Row>
                          {imageGrid?.images?.map((gridImage, index) => (
                            <Col
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              key={`index${index}`}
                              className="mb-4"
                            >
                              <div>
                                <div
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ minHeight: 200 }}
                                >
                                  <Image
                                    src={gridImage}
                                    style={{
                                      objectFit: "cover",
                                      height: "100%",
                                      width: "100%",
                                      backgroundColor:
                                        blogPost?.imagesBackgroundColor ||
                                        colors.archDark,
                                      boxShadow: "0px 0px 10px 0px #ffffff99",
                                      borderRadius: "20px",
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Container>
                    )}
                    {accordions?.length && (
                      <>
                        {accordions?.map((accordion, index) => (
                          <Accordion
                            highlightColor={
                              blogPost?.highlightColor || colors.white
                            }
                            title={accordion?.title}
                            key={`index${index}`}
                            style={{ marginBottom: "5px" }}
                          >
                            <div
                              style={{ paddingLeft: "5%", paddingRight: "5%" }}
                            >
                              {accordion?.content?.map(
                                (
                                  {
                                    title: contentTitle,
                                    details,
                                    header,
                                    button: accordionButton,
                                    text,
                                    subtext,
                                    image: accordionImage,
                                    caption: accordionImageCaption,
                                    url: accordionLink,
                                    spacer: accordionSpacer,
                                  },
                                  sectionsIndex
                                ) => (
                                  <div
                                    style={{ marginBottom: "50px" }}
                                    key={`index${sectionsIndex}`}
                                  >
                                    {contentTitle && (
                                      <div
                                        onClick={
                                          accordionLink
                                            ? () =>
                                                onPressOpenLinkInNewTab({
                                                  link: accordionLink,
                                                })
                                            : undefined
                                        }
                                        style={{
                                          cursor: accordionLink
                                            ? "pointer"
                                            : undefined,
                                        }}
                                      >
                                        <h4
                                          style={{
                                            marginTop: "30px",
                                            marginBottom: "15px",
                                            textAlign: "left",
                                            color: blogPost?.highlightColor,
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {contentTitle}
                                        </h4>
                                      </div>
                                    )}
                                    {accordionImage && (
                                      <>
                                        <Image
                                          src={accordionImage}
                                          // alt={title}
                                          style={{
                                            marginTop: "30px",
                                            objectFit: "cover",
                                            height: "100%",
                                            width: "100%",
                                            backgroundColor:
                                              blogPost?.imagesBackgroundColor ||
                                              colors.archDark,
                                            boxShadow: `0px 0px 10px 0px ${blogPost?.highlightColor}99`,
                                            borderRadius: "20px",
                                            maxHeight: 800,
                                          }}
                                        />
                                        {accordionImageCaption && (
                                          <h6
                                            style={{
                                              marginTop: "5px",
                                              marginBottom: "5px",
                                              textAlign: "center",
                                              color: "#ffffff99",
                                              marginLeft: "10vw",
                                              marginRight: "10vw",
                                            }}
                                          >
                                            {accordionImageCaption}
                                          </h6>
                                        )}
                                      </>
                                    )}
                                    {accordionButton && (
                                      <Button
                                        size="lg"
                                        style={{
                                          backgroundColor: `${blogPost?.highlightColor}99`,
                                          borderWidth: 0,
                                          marginTop: "50px",
                                          ...accordionButton?.style,
                                        }}
                                        onClick={() =>
                                          onPressOpenLinkInNewTab({
                                            link: accordionButton?.url,
                                          })
                                        }
                                      >
                                        {accordionButton?.icon}
                                        <span style={{ marginLeft: "10px" }}>
                                          {accordionButton?.title}
                                        </span>
                                      </Button>
                                    )}
                                    {header && (
                                      <div>
                                        <h4
                                          style={{
                                            marginTop: "10px",
                                            marginBottom: "15px",
                                            textAlign: "left",
                                            color: blogPost?.highlightColor,
                                            textDecoration: "underline",
                                          }}
                                        >
                                          {header}
                                        </h4>
                                      </div>
                                    )}
                                    {text && (
                                      <>
                                        <h5
                                          style={{
                                            marginTop: "15px",
                                            marginBottom: "15px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {` ▻ ${text}`}
                                        </h5>
                                        <p
                                          style={{
                                            textAlign: "left",
                                            fontSize: "20px",
                                          }}
                                        >
                                          {subtext}
                                        </p>
                                      </>
                                    )}
                                    {details?.length && (
                                      <>
                                        {details?.map(
                                          (detail, detailsIndex) => (
                                            <h6
                                              style={{
                                                marginTop: "15px",
                                                marginBottom: "15px",
                                                textAlign: "left",
                                              }}
                                              key={`index${detailsIndex}`}
                                            >
                                              {` ▻ ${detail}`}
                                            </h6>
                                          )
                                        )}
                                      </>
                                    )}
                                    {accordionSpacer && (
                                      <div
                                        style={{ height: accordionSpacer }}
                                      />
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </Accordion>
                        ))}
                      </>
                    )}
                    {spacer && <div style={{ height: spacer }} />}
                  </Col>
                </div>
              )
            )}
          </Container>
        </div>
      ) : (
        <>
          <Container>
            <h1>{"404 Error: Portfolio Blog Post Not Found"}</h1>
            <p>
              {
                "Oops, it looks like you stumbled upon a link that has either been deleted or has never existed."
              }
            </p>
            <iframe
              title={"404 Error: Portfolio Blog Post Not Found"}
              src={"https://giphy.com/embed/1Be4g2yeiJ1QfqaKvz"}
              width={`${480 / 1.6}`}
              height={`${362 / 1.6}`}
              allowFullScreen={false}
              style={{ borderRadius: "20px" }}
            />
            <h1 style={{ marginTop: "20px" }}>
              <a href={"/portfolio"} style={{ color: colors.green }}>
                {"Click here to view my entire Portfolio."}
              </a>
            </h1>
          </Container>
        </>
      )}
    </Screen>
  );
};
