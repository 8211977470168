import { useMemo, useContext } from "react";
import { WebContext } from "../../contexts/web/web-context-provider";
import { useLocation } from "react-router-dom";
import { useBlogPosts } from "../../scripts/use-blog-posts/use-blog-posts";

export const useBlogPostFunctions = () => {
  const { blogPosts } = useBlogPosts();

  const { onPressOpenLinkInNewTab } = useContext(WebContext);
  const location = useLocation();

  const nameOfCurrentRoute = useMemo(() => {
    const fullPathPortfolio = location?.pathname?.split("/portfolio/");
    const fullPathTravels = location?.pathname?.split("/travels/");
    const path =
      fullPathPortfolio.length > fullPathTravels.length
        ? fullPathPortfolio
        : fullPathTravels;
    const pathWithoutTrailingSlash = path?.[1]
      ?.toLowerCase()
      ?.replace(/\/$/, "");
    return pathWithoutTrailingSlash;
  }, [location.pathname]);

  const blogPost = useMemo(() => {
    return blogPosts?.find((blogPost) => blogPost.route === nameOfCurrentRoute);
  }, [blogPosts, nameOfCurrentRoute]);

  return {
    onPressOpenLinkInNewTab,
    blogPost,
  };
};
