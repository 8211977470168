import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { WebContextProvider } from "./contexts/web/web-context-provider";
import { Biography } from "./screens/biography/biography";
import { Portfolio } from "./screens/portfolio/portfolio";
import { BlogPost } from "./screens/blog-post/blog-post";
import { Schedule } from "./screens/schedule/schedule";
import { Home } from "./screens/home/home";
import * as React from "react";
import "./App.css";
import { Resume } from "./screens/resume/resume";

const App = () => {
  return (
    <WebContextProvider>
      <BrowserRouter>
        <Routes>
          {/* 👇 MAIN ROUTES */}
          <Route path="/" element={<Home />} />
          <Route path="/biography" element={<Biography />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/*" element={<BlogPost />} />
          <Route path="/travels/*" element={<BlogPost />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="*" element={<Navigate to="/" />} />
          {/* 👇 OLD ROUTES */}
          {[
            "/finequities",
            "/miami-beach-attractions",
            "/aws-certified-cloud-practitioner-study-guide",
            "/tensorflow-developer-certification-study-guide",
            "/data-robot-10x-data-science-academy-certification-guide",
            "/client-customer-management-manager-organizer-mobile-iphone-ios-android-app-tool-for-gym-trainers-training-club",
          ].map((pathName, index) => {
            return (
              <Route
                path={`/${pathName}`}
                key={`index${index}`}
                element={
                  <Navigate
                    to={`/portfolio${pathName}`}
                    key={`index${index}${pathName}`}
                  />
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </WebContextProvider>
  );
};

export default App;
