

import React, { useMemo } from "react"
import "../../App.css"
import { useLocation } from 'react-router-dom'
import { BsHeartPulse } from 'react-icons/bs';
import { TfiBriefcase } from 'react-icons/tfi';
import { GiPikeman, GiMagnifyingGlass } from 'react-icons/gi';
import { HoverText } from "../hover-text/hover-text";
import { colors } from '../../assets/constants/colors'

/**
 * Navbar that's contained in Header/Footer for navigating between primary screens
 */
export const Navbar = () => {
    const location = useLocation();

    const styles = useMemo(() => {
        return {
            icon: {
                width: '100px',
                highlightedColor: colors.primary,
                color: '#ffffff99',
            },
            iconContainer: {
                marginRight: 5,
                marginTop: -4
            },
        }
    }, [])

    return (
        <div style={{ paddingTop: 20, paddingBottom: 20, display: 'flex', justifyContent: 'center' }}>
            {[
                {
                    name: 'Home',
                    path: '/',
                    iconFunction: (isHighlighted) => <GiPikeman color={isHighlighted ? styles.icon.highlightedColor : styles.icon.color} size={styles.icon.size} style={styles.iconContainer} />
                },
                {
                    name: 'Biography',
                    path: '/biography',
                    iconFunction: (isHighlighted) => <BsHeartPulse color={isHighlighted ? styles.icon.highlightedColor : styles.icon.color} size={styles.icon.size} style={styles.iconContainer} />
                },
                {
                    name: 'Portfolio',
                    path: '/portfolio',
                    iconFunction: (isHighlighted) => <TfiBriefcase color={isHighlighted ? styles.icon.highlightedColor : styles.icon.color} size={styles.icon.size} style={styles.iconContainer} />
                },
                {
                    name: 'Resumé',
                    path: '/resume',
                    iconFunction: (isHighlighted) => <GiMagnifyingGlass color={isHighlighted ? styles.icon.highlightedColor : styles.icon.color} size={styles.icon.size} style={styles.iconContainer} />
                }
            ].map((item, index) => {
                const isCurrent = location?.pathname === item?.path
                return <div onClick={item?.onPress} key={`index${index}${item.name}`} style={{ backgroundColor: isCurrent ? `${colors.primary}40` : 'transparent', borderRadius: 7, cursor: 'pointer' }}>
                    <HoverText
                        text={item.name}
                        link={item.path}
                        icon={item?.iconFunction(isCurrent)}
                        textStyle={{ color: isCurrent ? colors.primary : '#ffffff99', fontWeight: isCurrent ? 'bold' : 'normal', textDecorationLine: 'none' }}
                        textClassName={"navbar-text"}
                    />
                </div>
            })}
        </div >
    );
};
