import React, { useMemo } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { colors } from '../../assets/constants/colors';
import './button.css';

export const Button = ({
    text,
    renderText,
    style,
    textStyle,
    onPress,
    rightIcon,
    leftIcon,
}) => {

    const styles = useMemo(() => ({
        view: {
            backgroundColor: colors.green,
            marginTop: 10,
            marginBottom: 10,
            borderWidth: 1,
            borderColor: `${colors.white}50`,
            ...style
        },
        text: {
            color: colors.white,
            marginTop: 7,
            fontFamily: 'bold',
            ...textStyle
        },
        leftIcon: {
            marginRight: 10,
        },
        rightIcon: {
            marginLeft: 10,
        }
    }), [style, textStyle])

    return (
        <BootstrapButton onClick={onPress} className={`button`} style={styles.view}  >
            <h5 className={"button-text"} style={styles.text}>
                <span style={styles.leftIcon}>
                    {leftIcon}
                </span>
                {renderText || text || 'Click Me!'}
                <span style={styles.rightIcon}>
                    {rightIcon}
                </span>
            </h5>
        </BootstrapButton>
    )
}
