import { Screen } from "../../components/screen/screen";
import { colors } from "../../assets/constants/colors";
import { MdEventAvailable } from 'react-icons/md';
import { InlineWidget } from "react-calendly";
import * as React from "react";
import "../../App.css"

/**
 * Schedule Screen
 */
export const Schedule = () => {

    return (
        <Screen>
            <div>
                <MdEventAvailable color={`${colors.primary}99`} size={'100px'} style={{ marginBottom: '10px' }} />
                <h1 className={"title"}>
                    <span style={{ color: '#ffffff99' }}>{'SCHEDULE'}</span>
                </h1>
                <InlineWidget
                    url="https://calendly.com/zernach/landscapesupplyapp?hide_event_type_details=1&hide_gdpr_banner=1"
                    styles={{
                        height: '1000px',
                        width: '80vw',
                    }}
                />
            </div>
        </Screen >
    );
};