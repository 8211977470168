import React, { useState } from 'react';
import { colors } from '../../assets/constants/colors'

export const HoverText = ({
    text,
    hoverColor = colors.primary,
    defaultColor = '#c1c1c1',
    textStyle,
    icon,
    link,
    highlighted,
    textClassName,
}) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const _textStyle = {
        ...textStyle,
        color: (highlighted || isHovering) ? hoverColor : defaultColor,
        transition: 'color 0.3s'
    };

    return (
        <a
            href={link}
            style={_textStyle}
            className={textClassName}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {icon}
            {text}
        </a>
    );
}
