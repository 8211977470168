import { useOnPressCopy } from "../../scripts/use-on-press-copy/use-on-press-copy";
import { WebContext } from "../../contexts/web/web-context-provider";
import { colors } from "../../assets/constants/colors";
import React, { useContext, useState } from "react";
import { Navbar } from "../navbar/navbar";
import { Button } from "../button/button";
import { FiCopy, FiMail } from "react-icons/fi";
import { ImCheckmark } from "react-icons/im";
import { socialIcons } from "../../constants/social-icons";
import "../../App.css";

/**
 * Footer with contact information (to be shown on all screens)
 */
export const Footer = () => {
  const { onPressOpenLinkInNewTab, onPressOpenLink } = useContext(WebContext);

  const { onPressCopy: onPressCopyEmail } = useOnPressCopy({
    text: "Ryan@Zernach.com",
  });

  const [hasBeenCopied, sethasBeenCopied] = useState(false);
  const currentYear = new Date().getFullYear();

  const onPressEmailButton = () => {
    onPressCopyEmail();
    sethasBeenCopied(true);
    setTimeout(() => {
      sethasBeenCopied(false);
    }, 1700);
  };

  return (
    <footer className="vshapedTopDivider">
      <div
        style={{
          backgroundColor: "#00000099",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <div
          className="centerEverything"
          style={{ paddingTop: 2, paddingBottom: 5 }}
        >
          <Navbar />
          <div
            className="centerEverything"
            style={{
              width: "68%",
              height: 1,
              backgroundColor: "#ffffff50",
              marginLeft: "16%",
              marginTop: 0,
              marginBottom: 10,
            }}
          />
          <div
            className="flexed"
            style={{
              justifyContent: "center",
              paddingTop: 10,
              paddingBottom: 5,
            }}
          >
            {socialIcons.map((item, index) => (
              <div
                key={`index${index}`}
                onClick={() => onPressOpenLinkInNewTab({ link: item?.path })}
                className={item.class}
                alt={item.name}
                style={{
                  backgroundColor: item.tintColor,
                  marginLeft: 7,
                  marginRight: 7,
                  cursor: "pointer",
                }}
              />
            ))}
          </div>
          <h1
            onClick={() =>
              onPressOpenLinkInNewTab({
                link: "https://www.google.com/search?q=zernach",
              })
            }
            style={{
              color: "#ffffff99",
              marginTop: 6,
              fontFamily: "thin",
              cursor: "pointer",
            }}
          >
            {"@ZERNACH"}
          </h1>
          <img
            src={require("../../assets/images/other/zernach.png")}
            className="zernach-icon"
            alt="zernach-icon"
            style={{}}
          />
          <div style={{ marginTop: 5 }}>
            <img
              onClick={() =>
                onPressOpenLinkInNewTab({ link: "https://stemtechnetwork.com" })
              }
              src={require("../../assets/images/other/stem-icons.png")}
              className="stem-icons"
              alt="stem-icons"
              style={{ marginBottom: 10, cursor: "pointer" }}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <Button
              leftIcon={hasBeenCopied ? null : <FiMail />}
              rightIcon={
                hasBeenCopied ? (
                  <ImCheckmark style={{ marginTop: -5 }} />
                ) : (
                  <FiCopy />
                )
              }
              onPress={onPressEmailButton}
              renderText={
                hasBeenCopied ? (
                  <span>{"COPIED!"}</span>
                ) : (
                  <span style={{ fontFamily: "regular" }}>
                    {"RYAN"}
                    <span style={{ fontFamily: "thin" }}>{"@ZERNACH.COM"}</span>
                  </span>
                )
              }
              style={{
                backgroundColor: hasBeenCopied
                  ? `${colors.green}`
                  : `${colors.beach}25`,
                opacity: hasBeenCopied ? 1 : undefined,
                marginRight: 4,
                marginLeft: 4,
                marginTop: 8,
                marginBottom: 15,
              }}
              textStyle={{
                color: hasBeenCopied ? colors.archDark : undefined,
              }}
            />
          </div>
          <div
            onClick={() =>
              onPressOpenLink({ link: "/portfolio/miami-beach-attractions" })
            }
            style={{ cursor: "pointer" }}
          >
            <div style={{ marginTop: 5 }}>
              <img
                src={require("../../assets/images/backgrounds/miamibeach-logo.png")}
                className="stem-icons"
                alt="stem-icons"
                style={{
                  marginBottom: 10,
                  cursor: "pointer",
                  width: "200px",
                  height: "100%",
                }}
              />
            </div>
            <h5
              className={"footerNotes"}
              style={{
                color: "#ffffff99",
                marginBottom: 2,
                fontFamily: "bold",
                fontSize: "medium",
              }}
            >
              {`All Rights Reserved © ${currentYear}`}
            </h5>
          </div>
        </div>
      </div>
    </footer>
  );
};
