import { WebContext } from "../../contexts/web/web-context-provider"
import { Screen } from "../../components/screen/screen"
import { Button } from "../../components/button/button"
import { BsBoxArrowUpRight } from 'react-icons/bs'
import React, { useContext } from "react"
import "../../App.css"

/**
 * Resume Screen
 */

export const Resume = () => {
    const resumeLink = 'https://zernach.github.io/resume/Ryan_Zernach_Resume.pdf?raw=true'
    const { onPressOpenLinkInNewTab } = useContext(WebContext)
    const onPressResumeDownload = () => onPressOpenLinkInNewTab({ link: resumeLink })
// more
    return (
        <Screen>
            <div>
                <div style={{ marginBottom: 20 }}>
                    <Button
                        rightIcon={<BsBoxArrowUpRight style={{ marginTop: -5 }} />}
                        onPress={onPressResumeDownload}
                        text={'Open PDF in New Tab'}
                        style={{
                            backgroundColor: '#72fa4150',
                            marginRight: 4,
                            marginLeft: 4,
                            marginTop: 8,
                            marginBottom: 15
                        }}
                    />
                </div>
                <iframe
                    title={'Ryan-Zernach-Resume-Mobile-App-Developer-Web-Developer-Software-Engineer-Machine-Learning-Data-Science'}
                    src={resumeLink}
                    style={{ width: "90vw", maxWidth: '834px', borderRadius: "20px", boxShadow: '0px 0px 10px 0px #ffffff99', alignSelf: 'center' }}
                    allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"}
                    allowFullScreen={true}
                    height={'2197px'}
                />
            </div>
        </Screen >
    );
};