import { useCallback } from "react"

export const useOnPressCopy = ({ text }) => {

    const onPressCopy = useCallback(async () => {
        if (text) {
            navigator.clipboard.writeText(text).then(
                function () {
                    console.log('Async: Copying to clipboard was successful!');
                },
                function () {
                    console.error('Async: Could not copy text: ');
                }
            )
        }
    }, [text])

    return {
        onPressCopy,
    }

}