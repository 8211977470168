import { WebContext } from "../../contexts/web/web-context-provider";
import { Accordion } from '../../components/accordion/accordion'
import { useBiographyFunctions } from "./biography-functions";
import { Col, Image, Row, Container } from "react-bootstrap";
import { Screen } from "../../components/screen/screen";
import { colors } from "../../assets/constants/colors";
import { BsHeartPulse } from 'react-icons/bs';
import { Audio } from '../../components/audio/audio';
import { useContext } from "react";
import "../../App.css"

/**
 * Biography Screen
 */
export const Biography = () => {

    const {
        mindsetMattersMostGrid,
        booksWorthYourTimeGrid,
        roleModelsGrid,
        neverStopExploringImages,
        introductionBio,
    } = useBiographyFunctions()

    const { onPressOpenLinkInNewTab } = useContext(WebContext)

    return (
        <Screen containerClass={'screen-vertical'}>
            <div>
                <BsHeartPulse color={`${colors.primary}99`} size={'100px'} style={{ marginBottom: '10px' }} />
                <h1 className={'title'}>
                    <span style={{ color: '#ffffff99' }}>{'AUTO-'}</span><span style={{ color: `${colors.primary}99` }}>{`BIOGRAPHY`}</span>
                </h1>
            </div>
            <h2 style={{ color: '#ffffff99' }}>{`(an ever-evolving work-in-progress)`}</h2>
            <h4 style={{ color: '#ffffff99' }}>{`LAST UPDATED: 05/20/2023`}</h4>
            <div style={{ marginTop: 30 }}>
                <Audio src={require("../../assets/audio/Kygo_Freeze.mp3")} />
            </div>
            <div>
                <div className="flexed" style={{ flexDirection: 'column', marginTop: '10vh' }}>
                    <div className="container" style={{ width: '100vw' }}>
                        <Accordion startIsOpen={true} highlightColor={colors.primary} title="♻️ Introduction" style={{ marginBottom: '10px' }}>
                            <div style={{ margin: '5vh' }}>
                                {introductionBio.map((intro, index) => (
                                    <Row key={`${index}`} className="justify-content-center align-items-center" style={{ marginTop: '40px' }}>
                                        <Col lg={{ span: 4, order: (index + 2) % 2 ? 2 : 1 }} xs={{ span: 12, order: (index + 2) % 2 ? 2 : 2 }} >
                                            <Row
                                                className="d-flex justify-content-center align-items-center"
                                                style={{ minHeight: 200 }}
                                            >
                                                {intro?.image &&
                                                    <Image
                                                        src={intro?.image}
                                                        alt={intro?.imageTitle}
                                                        style={{ borderRadius: '40px', marginTop: '30px' }}
                                                    />
                                                }
                                                {intro?.video &&
                                                    <iframe
                                                        title={intro?.imageTitle}
                                                        src={intro?.video}
                                                        style={{ marginRight: '-20px', width: "44vw", height: "100%", borderRadius: "20px", boxShadow: '0px 0px 10px 0px #ffffff99', alignSelf: 'center' }}
                                                        allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"}
                                                        allowFullScreen={true}
                                                    />
                                                }
                                                <p style={{ fontSize: '15px', marginTop: '10px', color: '#ffffff99' }}>
                                                    {intro?.imageCaption}
                                                </p>
                                            </Row>
                                        </Col>
                                        <Col lg={{ span: 8, order: (index + 2) % 2 ? 1 : 2 }} xs={{ span: 12, order: (index + 2) % 2 ? 1 : 1 }} className="d-flex justify-content-center align-items-center" style={{ paddingLeft: 30, paddingRight: 30 }}>
                                            <div style={{ marginBottom: '30px' }}>
                                                <h2 style={{ color: '#ffffff', fontFamily: 'Thin' }}>
                                                    {intro?.text}
                                                </h2>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </Accordion>
                        <Accordion startIsOpen={true} highlightColor={colors.blue} title={"🌎 Mindset Matters Most"} style={{ marginBottom: '10px' }}>
                            <Container style={{ marginTop: '1vh' }}>
                                <Row>
                                    {mindsetMattersMostGrid.map((app, index) => (
                                        <Col xs={12} sm={12} md={6} lg={3} key={`index${index}`} className="mb-4">
                                            <Row className="d-flex justify-content-center align-items-center" style={{}} >
                                                <Col xs={3} sm={3}>
                                                    {app.icon}
                                                </Col>
                                                <Col xs={9} sm={9}>
                                                    <h5 style={{ marginTop: '2vh', color: '#ffffff', textAlign: 'left' }}>
                                                        {app.text}
                                                    </h5>
                                                </Col>
                                            </Row>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </Accordion>
                        <Accordion highlightColor={'#fbff00'} title={"💡 Books Worth Your Time"} style={{ marginBottom: '10px' }}>
                            <div style={{ padding: 10, }}>
                                <table style={{ borderRadius: '10px', overflow: 'hidden' }}>
                                    <thead>
                                        <tr>
                                            <th>{'📚 Book Title'}</th>
                                            <th>{'👤 Author(s)'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {booksWorthYourTimeGrid.map((book, index) => (
                                            <tr onClick={() => onPressOpenLinkInNewTab({ link: book?.link })} key={`index${index}`} style={{ cursor: 'pointer' }} >
                                                <td>{book.title}</td>
                                                <td style={{ fontFamily: 'Thin' }}>{book.author}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>
                        <Accordion highlightColor={'#ff69b4'} title={"🧠 Exceptional Individuals"} style={{ marginBottom: '10px' }}>
                            <Container style={{ padding: '5vh' }}>
                                <Row>
                                    {roleModelsGrid.map((person, index) => (
                                        <Col xs={12} sm={6} md={4} lg={3} key={`index${index}`} className="mb-4">
                                            <div>
                                                <div
                                                    className="d-flex justify-content-center align-items-center"
                                                // style={{ minHeight: 200 }}
                                                >
                                                    {/* <Image
                                                        src={app.image}
                                                        alt={app.name}
                                                        style={{ objectFit: 'cover', height: '100%', width: '100%', backgroundColor: '#1c1c1c', boxShadow: '0px 0px 10px 0px #ffffff99', borderRadius: '40px' }}
                                                    /> */}
                                                </div>
                                                <h3 style={{ marginTop: '2vh', color: '#ffffff' }}>
                                                    {person.name}
                                                </h3>
                                            </div>
                                            <div style={{ marginTop: '2vh' }}>
                                                <div key={`index${index}`}>
                                                    <p style={{ color: '#ffffff99', fontSize: '1.1rem', marginRight: '-10px', marginLeft: '-10px' }}>
                                                        {person.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </Accordion>
                    </div>
                    <div style={{ marginTop: '200px' }}>
                        <h2 style={{ textAlign: 'center', color: '#ffffff99' }}>
                            {'Never Stop Exploring'}
                        </h2>
                        {neverStopExploringImages.map((item, index) => (
                            <Col key={`index${index}`} className="d-flex justify-content-center align-items-center">
                                <Image
                                    src={item?.image}
                                    style={{ objectFit: 'cover', height: '100%', width: '96%', marginTop: '20px', backgroundColor: '#1c1c1c', boxShadow: '0px 0px 10px 0px #ffffff99', borderRadius: '40px' }}
                                />
                                <Col className="d-flex justify-content-center align-items-center" style={{ position: 'absolute', backgroundColor: '#1c1c1c99', borderRadius: 20 }}>
                                    <p style={{ marginTop: 12, paddingLeft: 30, paddingRight: 30 }}>{item?.title}</p>
                                </Col>
                            </Col>
                        ))}
                    </div>
                </div>
            </div >
        </Screen >
    )
}