import { colors } from "../../assets/constants/colors";
import "../../App.css";
import { WebContext } from "../../contexts/web/web-context-provider";
import { useContext } from "react";

export const Tabs = ({ tabs, tab: currentTab, setTab, icons }) => {
  const { windowWidth } = useContext(WebContext);
  const onPressTab = (tab) => {
    // window.scrollTo({ top: 350, behavior: "smooth" });
    setTab(tab);
  };

  return (
    <div style={{ width: windowWidth * 0.9, maxWidth: "1000px" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        {Object.values(tabs).map((tab, index) => {
          return (
            <div
              className="hover"
              key={tab}
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "700px",
                backgroundColor: `${colors.archDark}99`,
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: colors.green,
                borderRadius: "10px",
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "180px",
              }}
              onClick={() => onPressTab(tab)}
            >
              <div style={{ alignItems: "center" }}>
                {icons?.[index] && (
                  <div>
                    <p
                      style={{
                        marginBottom: "10px",
                        fontSize: "100px",
                      }}
                    >
                      {icons[index]}
                    </p>
                  </div>
                )}
                <div>
                  <h2 className={"hFlex"}>{tab}</h2>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
