import React from "react";
import styles from "./alert.module.css";

export const Alert = ({ title, message, onClose }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.alert}>
        <div className={styles.title}>{title}</div>
        <div className={styles.message}>{message}</div>
        <button className={styles.closeButton} onClick={onClose}>
          {"Okay, thanks!"}
        </button>
      </div>
    </div>
  );
};
