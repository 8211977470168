import React from "react";
import "../../App.css";
import { Image, Col } from "react-bootstrap";

export const PhoneIcons = ({ icons, onPressStyle }) => {
  const colProps = {
    xs: 3,
    sm: 3,
    md: 3,
    lg: 2,
    className: "hover",
  };
  const phoneIconStyle = {
    cursor: "pointer",
    ...onPressStyle,
  };
  const imageDivClasses = "d-flex justify-content-center align-items-center";
  const appNameTextStyle = {
    marginTop: "1.5vh",
    color: "#ffffff",
    textAlign: "center",
    fontSize: "1rem",
  };
  const appImageStyle = {
    objectFit: "cover",
    height: "60%",
    width: "60%",
    borderRadius: "13px",
  };
  return (
    <>
      {icons.map((app, index) => (
        <>
          {app?.type === "folder" ? (
            <Col {...colProps} key={`index${index}`}>
              <div onClick={app?.onPress} style={phoneIconStyle}>
                <div className={imageDivClasses}>
                  <Image
                    src={require("../../assets/images/new-home/app_folder_dark_transparent_icons.png")}
                    alt={app.name}
                    style={appImageStyle}
                  />
                </div>
                <h6 style={appNameTextStyle}>{app.name}</h6>
              </div>
            </Col>
          ) : (
            <Col {...colProps} key={`index${index}`}>
              <div onClick={app?.onPress} style={phoneIconStyle}>
                <div className={imageDivClasses}>
                  <Image src={app.image} alt={app.name} style={appImageStyle} />
                </div>
                <h6 style={appNameTextStyle}>{app.name}</h6>
              </div>
            </Col>
          )}
        </>
      ))}
    </>
  );
};
