

import * as React from "react"
import "../../App.css"
import Carousel from 'react-bootstrap/Carousel';

/**
 * Describe the new component here...
 */
export const CustomCarousel = () => {
    return (
        <Carousel indicatorLabels={false}>
            {[
                {
                    smallText: 'SIMPLIFYING THE',
                    bigText: 'COMPLEX'
                },
                {
                    smallText: 'ENGINEERING A BRIGHTER',
                    bigText: 'FUTURE'
                },
                {
                    smallText: 'MAKING THE IMPOSSIBLE',
                    bigText: 'POSSIBLE'
                },
                {
                    smallText: 'ORGANIZING WITH',
                    bigText: 'CREATIVITY'
                }
            ].map((item, index) => (
                <Carousel.Item key={`index${index}`} style={styles.carouselItem}>
                    <p style={styles.smallText} className="carouselItem">{item.smallText}</p>
                    <h3 style={styles.bigText}>{item.bigText}</h3>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

const styles = {
    smallText: {
        marginBottom: 0,
        marginTop: 36,
        color: '#ffffff75',
        fontSize: '13.5px',
        fontFamily: "Regular"
    },
    bigText: {
        marginBottom: 0,
        fontWeight: 'bold',
    },
    carouselItem: {
        height: '17vh',
        justifyContent: 'center',
        justifyItems: 'center',
        justifySelf: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    }
}